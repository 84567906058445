import MainDesign from "../../components/ui/MainDesign";
import StudentBoards from "../../components/tutor/student/StudentBoards";
import Meta from "../../components/common/Meta";
import React, {Suspense} from "react";
import {Await, defer, Params, redirect, useLoaderData} from "react-router-dom";
import {BoardService} from "../../service/BoardService";
import {ApiError} from "../../service/HttpService";
import {Board, CreateOrEditBoard} from "../../model/tutor/Board";
import LoadingPage from "../common/LoadingPage";
import {TutorService} from "../../service/TutorService";
import {Student, StudentResponse} from "../../model/tutor/Student";
import ErrorContent from "../../components/common/ErrorContent";

interface LoaderData {
    data: {
        boards: Promise<Board[]>
        studentResponse: Promise<StudentResponse>
    }
}


export default function StudentPage() {
    const {data} = useLoaderData() as LoaderData;
    return <>
        <MainDesign containerStyles='container py-16'>
            <Meta title='Tablice z uczniem'/>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={data} errorElement={<ErrorContent/>}>
                    {([boards, studentResponse]: [Board[], StudentResponse]) => <>
                        <StudentBoards boards={boards} student={studentResponse.student} readonly={studentResponse.readonly}/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

export async function getData(params: Params<"studentId">) {
    return Promise.all([
        BoardService.getAllBoards(params.studentId!!),
        TutorService.getStudent(params.studentId!!)
    ])
}

export async function loader({params}: { params: Params<"studentId"> }) {
    return defer({
        data: getData(params)
    });
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();

    const method = request.method;

    try {
        if (method === "DELETE") {
            const id = formData.get('id') as string;
            await BoardService.deleteBoard(id);

            return {
                status: 200,
                body: 'Tablica została usunięta.'
            };
        }

        const studentId = formData.get('studentId') as string

        const requestBody = {
            id: formData.get('id') as string,
            title: formData.get('title') as string,
            description: formData.get('description') as string,
            studentId: studentId
        } as CreateOrEditBoard;

        const requestType = formData.get('requestType') as 'CREATE' | 'EDIT';

        const isEditing = requestType === 'EDIT';
        let board;
        if (isEditing) {
            board = await BoardService.editBoard(requestBody);
        } else {
            board = await BoardService.addBoard(requestBody);
        }

        return isEditing ? {
            status: 200,
            body: isEditing ? 'Zmiany zostały zapisane.' : 'Tablica została utworzona pomyślnie.'
        } : redirect(`/t/${board.id}`);
    } catch (error) {
        const apiError = error as ApiError;
        console.error(apiError);
        return {
            status: apiError.httpsStatus || 500,
            body: apiError.message || "Wystąpił błąd."
        };
    }
}