import {AuthService, TokenPayload} from "../service/AuthService";
import {fetchAuthSession, JWT} from 'aws-amplify/auth';
import {redirect} from "react-router-dom";

async function getRawIdToken(): Promise<JWT | null> {
    try {
        await new Promise(resolve => setTimeout(resolve, 1));
        const {idToken} = (await fetchAuthSession()).tokens ?? {};

        return idToken ? idToken : null;
    } catch (err) {
        console.log(err);
        return null;
    }
}

export async function getRawIdTokenAsString(): Promise<string> {
    try {
        await new Promise(resolve => setTimeout(resolve, 1));
        const idToken = await getRawIdToken()

        return idToken ? idToken.toString() : '';
    } catch (err) {
        console.log(err);
        return '';
    }
}

export async function tokenLoader({request}: { request: Request }): Promise<TokenPayload | null> {
    const user = await AuthService.getCurrentlyLoggedInUser()
    return user ?? null;
}

export async function redirectIfNotLoggedIn(): Promise<Response | null> {
    const token = await getRawIdTokenAsString();

    if (!token) {
        return redirect('/logowanie');
    }
    return null;
}

export async function redirectIfLoggedIn(): Promise<Response | null> {
    const token = await getRawIdTokenAsString();

    if (token) {
        return redirect('/');
    }
    return null;
}