import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Amplify} from "aws-amplify";
import 'lazysizes';

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: process.env.REACT_APP_USER_POOL_ID!!,
            userPoolClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID!!,
            loginWith: {
                oauth: {
                    domain: `${process.env.REACT_APP_ENV}-auth-tnk.auth.eu-central-1.amazoncognito.com`,
                    scopes: ['email', 'profile', 'openid'],
                    responseType: 'token',
                    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN_COMMA_SEPARATED!!.split(','),
                    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT_COMMA_SEPARATED!!.split(',')
                }
            }
        }
    }
})


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);