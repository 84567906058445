import {Await, defer, useLoaderData, useRouteLoaderData, useSubmit,} from "react-router-dom";
import {TokenPayload} from "../../service/AuthService";
import MainDesign from "../ui/MainDesign";
import AnimatedButton from "../ui/motion/AnimatedButton";
import CustomSelect from "../ui/form/CustomSelect";
import StudentPreview from "./student/StudentPreview";
import {CreateOrEditStudent, Student} from "../../model/tutor/Student";
import React, {Suspense, useState} from "react";
import ConfirmModal from "../ui/modal/ConfirmModal";
import AddOrEditStudentModal from "./student/AddOrEditStudentModal";
import {useSubmitFormAction} from "../../hooks/useSubmitFormAction";
import StudentShareModal from "./student/StudentShareModal";
import {TutorService} from "../../service/TutorService";
import {ApiError} from "../../service/HttpService";
import LoadingPage from "../../pages/common/LoadingPage";
import {getRawIdTokenAsString} from "../../util/AuthUtil";
import ErrorContent from "../common/ErrorContent";
import {UserService} from "../../service/UserService";
import {User} from "../../model/tutor/User";
import SubscriptionWarning from "./transaction/SubscriptionWarning";

interface LoaderData {
    data: {
        students: Promise<Student[]>
        user: Promise<User>
    }
}

export default function TutorIndex() {
    const {data} = useLoaderData() as LoaderData;

    const {isSubmitting} = useSubmitFormAction("/", null, () => {
        setEditModalOpen(false);
        setDeleteModalOpen(false);
    });
    const submit = useSubmit();

    const [studentToEdit, setStudentToEdit] = useState<Student | null>(null);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const [studentToShare, setStudentToShare] = useState<Student | null>(null);
    const [shareModalOpen, setShareModalOpen] = useState(false);

    const [studentToDelete, setStudentToDelete] = useState<Student | null>(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState(""); // State for search term
    const [sortOption, setSortOption] = useState("newest-activity"); // State for sort option

    function submitDeleteForm() {
        submit(
            {
                id: studentToDelete!!.id,
            },
            {
                method: "DELETE",
            }
        );
    }

    return (
        <>
            <MainDesign containerStyles="py-16 container">
                <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                    <Await resolve={data} errorElement={<ErrorContent/>}>
                        {([students, user]: [Student[], User]) => {
                            const subscriptionExpired = new Date(user.subscriptionUntil).getTime() - new Date().getTime() < 0

                            const filteredStudents = students.filter((student) => {
                                const term = searchTerm.toLowerCase();
                                return (
                                    student.name.toLowerCase().includes(term) ||
                                    (student.lastName &&
                                        student.lastName.toLowerCase().includes(term)) ||
                                    (student.description &&
                                        student.description.toLowerCase().includes(term))
                                );
                            });

                            // Sort the filtered students based on sortOption
                            const sortedStudents = [...filteredStudents].sort((a, b) => {
                                switch (sortOption) {
                                    case "newest-activity":
                                        return (
                                            new Date(b.lastBoardOpenedAt).getTime() -
                                            new Date(a.lastBoardOpenedAt).getTime()
                                        );
                                    case "oldest-activity":
                                        return (
                                            new Date(a.lastBoardOpenedAt).getTime() -
                                            new Date(b.lastBoardOpenedAt).getTime()
                                        );
                                    case "a-z":
                                        return a.name.localeCompare(b.name, "pl", {
                                            sensitivity: "base",
                                        });
                                    case "z-a":
                                        return b.name.localeCompare(a.name, "pl", {
                                            sensitivity: "base",
                                        });
                                    default:
                                        return 0;
                                }
                            });

                            return (
                                <>
                                    <h1 className="text-white text-center mb-10">
                                        Witaj, {user.name}!
                                    </h1>
                                    {
                                        new Date(user.subscriptionUntil).getTime() - new Date().getTime() <= 5 * 24 * 60 * 60 * 1000 && (() => {
                                            return (
                                                <>
                                                    {
                                                        new Date(user.subscriptionUntil).getTime() - new Date().getTime() <= 5 * 24 * 60 * 60 * 1000 && (
                                                            <SubscriptionWarning
                                                                expirationDate={new Date(user.subscriptionUntil)}
                                                            />
                                                        )
                                                    }
                                                </>
                                            );
                                        })()
                                    }

                                    <div className="mw-5xl mx-auto">
                                        {/* Row with button, search, and select */}
                                        <div className="row align-items-center">
                                            <div className="col-lg-3 col-md-4">
                                                <AnimatedButton
                                                    className="btn btn-lg btn-success text-success-light shadow w-100"
                                                    style={{whiteSpace: "nowrap"}}
                                                    disabled={subscriptionExpired}
                                                    onClick={() => setEditModalOpen(true)}
                                                >
                                                    Dodaj ucznia
                                                </AnimatedButton>
                                            </div>

                                            <div className="col-lg-5 col-md-4 mt-2 mt-md-0">
                                                <input
                                                    type="text"
                                                    className="form-control form-control-lg w-100 p-4 bg-dark text-white"
                                                    placeholder="Wyszukaj..."
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 mt-2 mt-md-0">
                                                <div className="d-inline-block w-100">
                                                    <CustomSelect
                                                        value={sortOption}
                                                        onChange={(e) => setSortOption(e.target.value)}
                                                        customStyles="form-control form-control-lg w-100 bg-dark text-white p-4"
                                                    >
                                                        <option value="newest-activity">
                                                            Sortuj: Najnowsza aktywność
                                                        </option>
                                                        <option value="oldest-activity">
                                                            Sortuj: Najstarsza aktywność
                                                        </option>
                                                        <option value="a-z">
                                                            Sortuj: Alfabetycznie, A-Z
                                                        </option>
                                                        <option value="z-a">
                                                            Sortuj: Alfabetycznie, Z-A
                                                        </option>
                                                    </CustomSelect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="text-white"/>
                                    <div className="mw-md-5xl mx-auto">
                                        <div className="mb-20 row g-6">
                                            {
                                                students.length === 0 && !subscriptionExpired && (
                                                    <div className='mx-3 mt-8'>
                                                        <h2>Nie masz jeszcze żadnych uczniów.</h2>
                                                        <p className='text-white fw-medium'>Dodaj pierwszego ucznia,
                                                            klikając w powyższy przycisk.</p>
                                                    </div>
                                                )
                                            }
                                            {
                                                subscriptionExpired ? <div className='mx-3 mt-8'>
                                                    <h2 className='text-warning'>Twoja subskrypcja wygasła</h2>
                                                    <p className='text-white fw-medium'>Odnów ją, aby dalej korzystać z serwisu. Nie martw się, Twoi uczniowie i tablice są w dalszym ciągu zapisane. Odzyskasz do nich dostęp po odnowieniu subskrypcji.</p>
                                                </div> : sortedStudents.map((student) => (
                                                    <React.Fragment key={student.id}>
                                                        <StudentPreview
                                                            key={student.id}
                                                            student={student}
                                                            setStudentToEdit={setStudentToEdit}
                                                            setStudentToDelete={setStudentToDelete}
                                                            setStudentToShare={setStudentToShare}
                                                            setShareModalOpen={setShareModalOpen}
                                                            setDeleteModalOpen={setDeleteModalOpen}
                                                            setEditModalOpen={setEditModalOpen}
                                                        />
                                                    </React.Fragment>
                                                ))}
                                        </div>
                                    </div>
                                </>
                            );
                        }}
                    </Await>
                </Suspense>
                <AddOrEditStudentModal
                    isOpen={editModalOpen}
                    setOpen={setEditModalOpen}
                    student={studentToEdit!!}
                    setStudentToEdit={setStudentToEdit}
                />
                <StudentShareModal
                    student={studentToShare!!}
                    isOpen={shareModalOpen}
                    setOpen={setShareModalOpen}
                    setStudentToShare={setStudentToShare}
                />
                <ConfirmModal
                    isOpen={deleteModalOpen}
                    setOpen={setDeleteModalOpen}
                    title={`Czy na pewno chcesz usunąć ucznia '${studentToDelete?.name}'?`}
                    subtitle="Tej czynności nie będzie dało się cofnąć."
                    isSubmitting={isSubmitting}
                    confirmButtonAction={submitDeleteForm}
                />
            </MainDesign>
        </>
    );
}

export async function loadAllData() {
    return Promise.all([
        TutorService.getAllStudents(),
        UserService.getUser()
    ]);
}

export async function loader({request}: { request: Request }) {
    const token = await getRawIdTokenAsString();
    return defer({
        data: token ? loadAllData() : [],
    });
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();

    const method = request.method;

    try {
        if (method === "DELETE") {
            const id = formData.get("id") as string;
            await TutorService.deleteStudent(id);

            return {
                status: 200,
                body: "Uczeń został usunięty.",
            };
        }

        const requestBody = {
            id: formData.get("id") as string,
            name: formData.get("name") as string,
            lastName: formData.get("lastName") as string,
            description: formData.get("description") as string,
        } as CreateOrEditStudent;

        const requestType = formData.get("requestType") as "CREATE" | "EDIT";

        const isEditing = requestType === "EDIT";
        if (isEditing) {
            await TutorService.editStudent(requestBody);
        } else {
            await TutorService.addStudent(requestBody);
        }

        return {
            status: 200,
            body: isEditing
                ? "Zmiany zostały zapisane."
                : "Uczeń został dodany pomyślnie.",
        };
    } catch (error) {
        const apiError = error as ApiError;
        console.error(apiError);
        return {
            status: apiError.httpsStatus || 500,
            body: apiError.message || "Wystąpił błąd.",
        };
    }
}