import {
    approximately,
    AssetRecordType,
    DefaultMainMenu,
    DefaultQuickActions,
    DefaultQuickActionsContent,
    Editor,
    exportToBlob,
    getHashForString,
    TLAssetStore,
    TLBookmarkAsset,
    TLComponents,
    Tldraw,
    TldrawUiMenuGroup,
    TldrawUiMenuItem,
    TLUiActionsContextType,
    TLUiOverrides,
    TLUiTranslationKey,
    uniqueId,
    useEditor,
    useIsDarkMode,
    useValue
} from "tldraw";
import 'tldraw/tldraw.css'
import React, {Suspense, useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useSync} from "@tldraw/sync";
import {Await, defer, Params, useLoaderData, useParams} from "react-router-dom";
import MainDesign from "../../components/ui/MainDesign";
import BoardShareModal from "../../components/tutor/board/BoardShareModal";
import {BoardService} from "../../service/BoardService";
import {BoardResponse} from "../../model/tutor/Board";
import LoadingPage from "./LoadingPage";
import BoardInfoModal from "../../components/tutor/board/BoardInfoModal";
import AddOrEditBoardModal from "../../components/tutor/board/AddOrEditBoardModal";
import ErrorContent from "../../components/common/ErrorContent";

const BACKEND_URL = process.env.REACT_APP_API_URL

type LoaderData = {
    boardResponse: Promise<BoardResponse>
}

export default function Whiteboard() {
    const {roomId} = useParams();

    const {boardResponse} = useLoaderData() as LoaderData;

    const [editBoardModalOpen, setEditBoardModalOpen] = React.useState(false);
    const [shareBoardModalOpen, setShareBoardModalOpen] = React.useState(false);
    const [boardInfoModalOpen, setBoardInfoModalOpen] = React.useState(false);

    const [redirectAfterEditUrl, setRedirectAfterEditUrl] = React.useState<string>("/");

    const [loadedBoardResponse, setLoadedBoardResponse] = React.useState<BoardResponse | null>(null);

    const store = useSync({
        // We need to know the websocket's URI...
        uri: `${BACKEND_URL}/connect/${roomId}`,

        // ...and how to handle static assets like images & videos
        assets: multiplayerAssets,
    })

    const editorRef = useRef<Editor | null>(null);
    const [editorReady, setEditorReady] = useState(false);

    useEffect(() => {
        const setVh = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setVh();
        window.addEventListener('resize', setVh);
        return () => {
            window.removeEventListener('resize', setVh);
        };
    }, []);


    useEffect(() => {
        let initialTimeoutId: any;
        let intervalId: any;

        if (loadedBoardResponse?.isTutor && editorReady) {
            const uploadFunction = async () => {
                try {
                    const shapeIds = editorRef.current!!.getCurrentPageShapeIds();
                    if (shapeIds.size === 0) {
                        // No shapes on the canvas
                        return;
                    }

                    // Export the board to a PNG blob
                    const blob = await exportToBlob({
                        editor: editorRef.current!!,
                        // @ts-ignore
                        ids: [...shapeIds],
                        format: 'png',
                        opts: {background: true, scale: 0.25, darkMode: true},
                    });

                    // Check if the blob size is under 2MB (2 * 1024 * 1024 bytes)
                    if (blob.size <= 2 * 1024 * 1024) {
                        const roomId = window.location.href.split("/t/")[1]?.split("?")[0];
                        await BoardService.uploadRoomPreview(roomId, blob);
                    }
                } catch (error) {
                    console.error('Error exporting and uploading board image:', error);
                }
            };

            // Initial delay of 30 seconds
            initialTimeoutId = setTimeout(() => {
                uploadFunction();

                // Then set up interval every 5 * 60 seconds
                intervalId = setInterval(uploadFunction, 5 * 60000);
            }, 30000); // 30000 milliseconds = 30 seconds
        }

        // Cleanup function to clear the interval and timeout
        return () => {
            if (initialTimeoutId) {
                clearTimeout(initialTimeoutId);
            }
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [loadedBoardResponse, loadedBoardResponse?.isTutor, editorReady]);

    function CustomQuickActions() {
        const editor = useEditor()
        return (
            <DefaultQuickActions>
                {/*@ts-ignore*/}
                <DefaultQuickActionsContent/>
                <div style={{cursor: 'pointer'}}>
                    <TldrawUiMenuItem id="code" icon="share-1" onSelect={() => setShareBoardModalOpen(true)}/>
                </div>
                <div style={{cursor: 'pointer'}}>
                    <TldrawUiMenuItem id="code" icon="info-circle" onSelect={() => setBoardInfoModalOpen(true)}/>
                </div>
                <div style={{cursor: 'pointer'}}>
                    <TldrawUiMenuItem id="code" icon={'fill-pattern'} onSelect={() => {
                        editor.updateInstanceState({isGridMode: !editor.getInstanceState().isGridMode})
                    }
                    }/>
                </div>
            </DefaultQuickActions>
        )
    }

    function CustomMainMenu() {
        const editor = useEditor(); // Access the editor instance

        const handleExport = useCallback(async () => {
            if (!editor) return;

            try {
                // Get all shape IDs from the current page
                const shapeIds = editor.getCurrentPageShapeIds();

                if (shapeIds.size === 0) {
                    alert('Tablica jest pusta.');
                    return;
                }

                // Export the board to a PNG blob
                const blob = await exportToBlob({
                    editor,
                    // @ts-ignore
                    ids: [...shapeIds], // Convert the Set to an Array
                    format: 'png',
                    opts: { background: true, scale: 1 }, // Scale of 1 for full resolution
                });

                const boardResp = await boardResponse;

                // Create a URL for the blob
                const url = URL.createObjectURL(blob);

                // Create an anchor element to trigger the download
                const a = document.createElement('a');
                a.href = url;
                const now = new Date();
                const formattedTime = now.toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false, // 24-hour format
                }).replace(/:/g, '-'); // Replace colons with dashes for filename compatibility
                a.download = `${boardResp.board.title} - ${formattedTime}.png`; // Default filename
                document.body.appendChild(a);
                a.click();

                // Clean up the URL object
                URL.revokeObjectURL(url);
                document.body.removeChild(a);
            } catch (error) {
                console.error('Error exporting the board:', error);
                alert('Failed to export the board. Please try again.');
            }
        }, [editor]);

        return (
            <DefaultMainMenu>
                {
                    loadedBoardResponse?.isTutor && <div>
                        {/*@ts-ignore*/}
                        <TldrawUiMenuGroup id="end-lesson">
                            <TldrawUiMenuItem
                                id="end-lesson"
                                label="Zakończ lekcję"
                                icon="external-link"
                                readonlyOk
                                onSelect={() => {
                                    setEditBoardModalOpen(true)
                                    setRedirectAfterEditUrl(loadedBoardResponse?.board?.studentId ? `/uczen/${loadedBoardResponse.board.studentId}` : '/')
                                }}
                            />
                        </TldrawUiMenuGroup>
                    </div>
                }
                <div>
                    {/* @ts-ignore*/}
                    <TldrawUiMenuGroup id="add-image">
                        <TldrawUiMenuItem
                            id="add-image"
                            label="Eksportuj"
                            icon="external-link"
                            readonlyOk
                            onSelect={handleExport} // Trigger image addition
                        />
                    </TldrawUiMenuGroup>
                </div>
                <div>
                    {/* @ts-ignore*/}
                    <TldrawUiMenuGroup id="add-image">
                        <TldrawUiMenuItem
                            id="add-image"
                            label="Zmień motyw"
                            icon="external-link"
                            readonlyOk
                            onSelect={() => {
                                editor.user.updateUserPreferences({colorScheme: editor.user.getUserPreferences().colorScheme === 'light' ? 'dark' : 'light'})
                            }} // Trigger image addition
                        />
                    </TldrawUiMenuGroup>
                </div>

                {/*<ExportFileContentSubMenu/>*/}
                {/*<LanguageMenu/>*/}
                {/*<DefaultMainMenuContent />*/}
                {/*<PreferencesGroup/>*/}
            </DefaultMainMenu>
        )
    }

    function drawLine(
        ctx: CanvasRenderingContext2D,
        x1: number,
        y1: number,
        x2: number,
        y2: number,
        width: number
    ) {
        ctx.beginPath()
        ctx.moveTo(x1, y1)
        ctx.lineTo(x2, y2)
        ctx.lineWidth = width
        ctx.stroke()
    }

    const overrides: TLUiOverrides = {
        //[a]
        actions(_editor, actions): TLUiActionsContextType {
            const newActions = {
                ...actions,
                'toggle-grid': {...actions['toggle-grid' as TLUiTranslationKey], kbd: 'x'},
                'toggle-dark-mode': {...actions['toggle-dark-mode' as TLUiTranslationKey], kbd: ''},
                'copy-as-png': {...actions['copy-as-png'], kbd: '$1'},
            }

            return newActions
        },
    }

    const components: TLComponents = {
        ActionsMenu: null,
        // DebugMenu: null,
        // ContextMenu: null,
        // HelpMenu: null,
        // KeyboardShortcutsDialog: null,
        MainMenu: CustomMainMenu,
        // StylePanel: null,
        // Toolbar: null,
        // ZoomMenu: null,
        QuickActions: CustomQuickActions,
        Grid: ({size, ...camera}) => {
            const editor = useEditor()

            // [2]
            const screenBounds = useValue('screenBounds', () => editor.getViewportScreenBounds(), [])
            const devicePixelRatio = useValue('dpr', () => editor.getInstanceState().devicePixelRatio, [])
            const isDarkMode = useIsDarkMode()

            const canvas = useRef<HTMLCanvasElement>(null)

            useLayoutEffect(() => {
                if (!canvas.current) return
                // [3]
                const canvasW = screenBounds.w * devicePixelRatio
                const canvasH = screenBounds.h * devicePixelRatio
                canvas.current.width = canvasW
                canvas.current.height = canvasH

                const ctx = canvas.current?.getContext('2d')
                if (!ctx) return

                // [4]
                ctx.clearRect(0, 0, canvasW, canvasH)

                // [5]
                const pageViewportBounds = editor.getViewportPageBounds()

                const startPageX = Math.ceil(pageViewportBounds.minX / size) * size
                const startPageY = Math.ceil(pageViewportBounds.minY / size) * size
                const endPageX = Math.floor(pageViewportBounds.maxX / size) * size
                const endPageY = Math.floor(pageViewportBounds.maxY / size) * size
                const numRows = Math.round((endPageY - startPageY) / size)
                const numCols = Math.round((endPageX - startPageX) / size)

                ctx.strokeStyle = isDarkMode ? '#555' : '#BBB'

                // [6]
                for (let row = 0; row <= numRows; row++) {
                    const pageY = startPageY + row * size
                    // convert the page-space Y offset into our canvas' coordinate space
                    const canvasY = (pageY + camera.y) * camera.z * devicePixelRatio
                    const isMajorLine = approximately(pageY % (size * 6), 0)
                    const skip = approximately(pageY % (size * 2), 0)
                    if (!skip) {
                        drawLine(ctx, 0, canvasY, canvasW, canvasY, isMajorLine ? 3 : 1)
                    }
                }
                for (let col = 0; col <= numCols; col++) {
                    const pageX = startPageX + col * size
                    // convert the page-space X offset into our canvas' coordinate space
                    const canvasX = (pageX + camera.x) * camera.z * devicePixelRatio
                    const isMajorLine = approximately(pageX % (size * 6), 0)
                    const skip = approximately(pageX % (size * 2), 0)
                    if (!skip) {
                        drawLine(ctx, canvasX, 0, canvasX, canvasH, isMajorLine ? 3 : 1)
                    }
                }
            }, [screenBounds, camera, size, devicePixelRatio, editor, isDarkMode])

            // [7]
            return <canvas className="tl-grid" ref={canvas}/>
        },
    }

    return <>
        <MainDesign minHeight='100vh'>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={boardResponse} errorElement={<ErrorContent/>}>
                    {(boardResponse: BoardResponse) => {
                        setLoadedBoardResponse(boardResponse);
                        return <>
                            {
                                boardResponse.isSubscriptionExpired ?
                                    <MainDesign containerStyles='py-20 container'>
                                        <ErrorContent messageProps='Nauczyciel nie ma aktywnej subsrypcji.'/>
                                    </MainDesign> :
                                    <div style={{height: 'calc(var(--vh, 1vh) * 100)'}}>
                                        <Tldraw
                                            components={components}
                                            store={store}
                                            autoFocus={true}
                                            inferDarkMode={true}
                                            overrides={overrides}
                                            onMount={(editor) => {
                                                editorRef.current = editor;
                                                setEditorReady(true);
                                                // @ts-expect-error
                                                window.editor = editor
                                                if (editor.getCurrentPage().name === 'Page 1') {
                                                    editor.renamePage(editor.getCurrentPage(), "Strona 1")
                                                }
                                                // when the editor is ready, we need to register our bookmark unfurling service
                                                editor.registerExternalAssetHandler('url', unfurlBookmarkUrl)
                                                editor.updateInstanceState({isGridMode: false})

                                                // Adjust the zoom level to fit the content
                                                // editor.zoomToFit()

                                                // Alternatively, to fit the page regardless of content:
                                                // editor.zoomToFitPage()
                                            }}
                                        />
                                    </div>
                            }

                            <AddOrEditBoardModal board={boardResponse.board} isOpen={editBoardModalOpen}
                                                 setOpen={setEditBoardModalOpen}
                                                 studentId={boardResponse.board.studentId}
                                                 customTitle='Wprowadź szczegóły zajęć.'
                                                 redirectAfterEditUrl={redirectAfterEditUrl}/>
                            <BoardShareModal isOpen={shareBoardModalOpen} setOpen={setShareBoardModalOpen}
                                             board={boardResponse.board}/>
                            <BoardInfoModal isOpen={boardInfoModalOpen} setOpen={setBoardInfoModalOpen}
                                            board={boardResponse.board}
                                            isTutor={boardResponse.isTutor}
                                            onEditInfoClick={() => {
                                                setBoardInfoModalOpen(false);
                                                setRedirectAfterEditUrl(`/t/${boardResponse.board.id}`);
                                                setEditBoardModalOpen(true);
                                            }}/>
                        </>
                    }}
                </Await>

            </Suspense>

        </MainDesign>
    </>
}


export const multiplayerAssets: TLAssetStore = {
    // to upload an asset, we prefix it with a unique id, POST it to our worker, and return the URL
    async upload(_asset, file) {
        const id = uniqueId()

        const roomId = window.location.href.split("/t/")[1]?.split("?")[0]; // Splits the string at "/t/" and ignores any query parameters.
        const objectName = `${id}-${file.name}`
        const url = `${BACKEND_URL}/uploads/${roomId}/${encodeURIComponent(objectName)}`

        const response = await fetch(url, {
            method: 'PUT',
            body: file,
        })

        if (!response.ok) {
            throw new Error(`Failed to upload asset: ${response.statusText}`)
        }

        return url
    },
    // to retrieve an asset, we can just use the same URL. you could customize this to add extra
    // auth, or to serve optimized versions / sizes of the asset.
    resolve(asset) {
        return asset.props.src
    },
}

// How does our server handle bookmark unfurling?
export async function unfurlBookmarkUrl({url}: { url: string }): Promise<TLBookmarkAsset> {
    const asset: TLBookmarkAsset = {
        id: AssetRecordType.createId(getHashForString(url)),
        typeName: 'asset',
        type: 'bookmark',
        meta: {},
        props: {
            src: url,
            description: '',
            image: '',
            favicon: '',
            title: '',
        },
    }

    try {
        const response = await fetch(`${BACKEND_URL}/unfurl?url=${encodeURIComponent(url)}`)
        const data = await response.json()

        asset.props.description = data?.description ?? ''
        asset.props.image = data?.image ?? ''
        asset.props.favicon = data?.favicon ?? ''
        asset.props.title = data?.title ?? ''
    } catch (e) {
        console.error(e)
    }

    return asset
}

export async function loader({params}: { params: Params<"roomId"> }) {
    const newRoomId = params.roomId;

    document.cookie = `room_id=${newRoomId}; path=/; domain=.${process.env.REACT_APP_FRONTEND_URL_RAW}; max-age=86400; SameSite=None; Secure`;

    return defer({
        boardResponse: await BoardService.getBoard(newRoomId!!)
    });
}
