import {
    approximately,
    DefaultMainMenu,
    DefaultQuickActions,
    DefaultQuickActionsContent,
    Editor,
    exportToBlob,
    TLComponents,
    Tldraw,
    TldrawUiMenuGroup,
    TldrawUiMenuItem,
    TLUiActionsContextType,
    TLUiOverrides,
    TLUiTranslationKey,
    useEditor,
    useIsDarkMode,
    useValue
} from "tldraw";
import 'tldraw/tldraw.css'
import React, {useCallback, useLayoutEffect, useRef} from "react";
import BoardInfoModal from "../../tutor/board/BoardInfoModal";
import BoardShareModal from "../../tutor/board/BoardShareModal";

export default function DemoWhiteboard() {

    const [shareBoardModalOpen, setShareBoardModalOpen] = React.useState(false);
    const [boardInfoModalOpen, setBoardInfoModalOpen] = React.useState(false);
    const editorRef = useRef<Editor | null>(null);

    function CustomQuickActions() {
        const editor = useEditor()
        return (
            <DefaultQuickActions>
                {/*@ts-ignore*/}
                <DefaultQuickActionsContent/>
                <div style={{cursor: 'pointer'}}>
                    <TldrawUiMenuItem id="code" icon="share-1" onSelect={() => setShareBoardModalOpen(true)}/>
                </div>
                <div style={{cursor: 'pointer'}}>
                    <TldrawUiMenuItem id="code" icon="info-circle" onSelect={() => setBoardInfoModalOpen(true)}/>
                </div>
                <div style={{cursor: 'pointer'}}>
                    <TldrawUiMenuItem id="code" icon={'fill-pattern'} onSelect={() => {
                        editor.updateInstanceState({isGridMode: !editor.getInstanceState().isGridMode})
                    }
                    }/>
                </div>
            </DefaultQuickActions>
        )
    }

    function CustomMainMenu() {
        const editor = useEditor(); // Access the editor instance

        const handleExport = useCallback(async () => {
            if (!editor) return;

            try {
                // Get all shape IDs from the current page
                const shapeIds = editor.getCurrentPageShapeIds();

                if (shapeIds.size === 0) {
                    alert('Tablica jest pusta.');
                    return;
                }

                // Export the board to a PNG blob
                const blob = await exportToBlob({
                    editor,
                    // @ts-ignore
                    ids: [...shapeIds], // Convert the Set to an Array
                    format: 'png',
                    opts: {background: true, scale: 1}, // Scale of 1 for full resolution
                });

                // Create a URL for the blob
                const url = URL.createObjectURL(blob);

                // Create an anchor element to trigger the download
                const a = document.createElement('a');
                a.href = url;
                const now = new Date();
                const formattedTime = now.toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false, // 24-hour format
                }).replace(/:/g, '-'); // Replace colons with dashes for filename compatibility
                a.download = `demo - ${formattedTime}.png`; // Default filename
                document.body.appendChild(a);
                a.click();

                // Clean up the URL object
                URL.revokeObjectURL(url);
                document.body.removeChild(a);
            } catch (error) {
                console.error('Error exporting the board:', error);
                alert('Failed to export the board. Please try again.');
            }
        }, [editor]);

        return <DefaultMainMenu>
            <div>
                {/* @ts-ignore*/}
                <TldrawUiMenuGroup id="add-image">
                    <TldrawUiMenuItem
                        id="add-image"
                        label="Eksportuj"
                        icon="external-link"
                        readonlyOk
                        onSelect={handleExport} // Trigger image addition
                    />
                </TldrawUiMenuGroup>
            </div>
            <div>
                {/* @ts-ignore*/}
                <TldrawUiMenuGroup id="add-image">
                    <TldrawUiMenuItem
                        id="add-image"
                        label="Zmień motyw"
                        icon="external-link"
                        readonlyOk
                        onSelect={() => {
                            editor.user.updateUserPreferences({colorScheme: editor.user.getUserPreferences().colorScheme === 'light' ? 'dark' : 'light'})
                        }} // Trigger image addition
                    />
                </TldrawUiMenuGroup>
            </div>
        </DefaultMainMenu>
    }

    function drawLine(
        ctx: CanvasRenderingContext2D,
        x1: number,
        y1: number,
        x2: number,
        y2: number,
        width: number
    ) {
        ctx.beginPath()
        ctx.moveTo(x1, y1)
        ctx.lineTo(x2, y2)
        ctx.lineWidth = width
        ctx.stroke()
    }

    const overrides: TLUiOverrides = {
        //[a]
        actions(_editor, actions): TLUiActionsContextType {
            const newActions = {
                ...actions,
                'toggle-grid': {...actions['toggle-grid' as TLUiTranslationKey], kbd: 'x'},
                'toggle-dark-mode': {...actions['toggle-dark-mode' as TLUiTranslationKey], kbd: ''},
                'copy-as-png': {...actions['copy-as-png'], kbd: '$1'},
            }

            return newActions
        },
    }

    const components: TLComponents = {
        ActionsMenu: null,
        // DebugMenu: null,
        // ContextMenu: null,
        // HelpMenu: null,
        // KeyboardShortcutsDialog: null,
        MainMenu: CustomMainMenu,
        // StylePanel: null,
        // Toolbar: null,
        // ZoomMenu: null,
        QuickActions: CustomQuickActions,
        Grid: ({size, ...camera}) => {
            const editor = useEditor()

            // [2]
            const screenBounds = useValue('screenBounds', () => editor.getViewportScreenBounds(), [])
            const devicePixelRatio = useValue('dpr', () => editor.getInstanceState().devicePixelRatio, [])
            const isDarkMode = useIsDarkMode()

            const canvas = useRef<HTMLCanvasElement>(null)

            useLayoutEffect(() => {
                if (!canvas.current) return
                // [3]
                const canvasW = screenBounds.w * devicePixelRatio
                const canvasH = screenBounds.h * devicePixelRatio
                canvas.current.width = canvasW
                canvas.current.height = canvasH

                const ctx = canvas.current?.getContext('2d')
                if (!ctx) return

                // [4]
                ctx.clearRect(0, 0, canvasW, canvasH)

                // [5]
                const pageViewportBounds = editor.getViewportPageBounds()

                const startPageX = Math.ceil(pageViewportBounds.minX / size) * size
                const startPageY = Math.ceil(pageViewportBounds.minY / size) * size
                const endPageX = Math.floor(pageViewportBounds.maxX / size) * size
                const endPageY = Math.floor(pageViewportBounds.maxY / size) * size
                const numRows = Math.round((endPageY - startPageY) / size)
                const numCols = Math.round((endPageX - startPageX) / size)

                ctx.strokeStyle = isDarkMode ? '#555' : '#BBB'

                // [6]
                for (let row = 0; row <= numRows; row++) {
                    const pageY = startPageY + row * size
                    // convert the page-space Y offset into our canvas' coordinate space
                    const canvasY = (pageY + camera.y) * camera.z * devicePixelRatio
                    const isMajorLine = approximately(pageY % (size * 6), 0)
                    const skip = approximately(pageY % (size * 2), 0)
                    if (!skip) {
                        drawLine(ctx, 0, canvasY, canvasW, canvasY, isMajorLine ? 3 : 1)
                    }
                }
                for (let col = 0; col <= numCols; col++) {
                    const pageX = startPageX + col * size
                    // convert the page-space X offset into our canvas' coordinate space
                    const canvasX = (pageX + camera.x) * camera.z * devicePixelRatio
                    const isMajorLine = approximately(pageX % (size * 6), 0)
                    const skip = approximately(pageX % (size * 2), 0)
                    if (!skip) {
                        drawLine(ctx, canvasX, 0, canvasX, canvasH, isMajorLine ? 3 : 1)
                    }
                }
            }, [screenBounds, camera, size, devicePixelRatio, editor, isDarkMode])

            // [7]
            return <canvas className="tl-grid" ref={canvas}/>
        },
    }

    return <>
        <div style={{height: '80vh'}}>
            <Tldraw
                components={components}
                autoFocus={true}
                inferDarkMode={true}
                overrides={overrides}
                onMount={(editor) => {
                    editorRef.current = editor;
                    // @ts-expect-error
                    window.editor = editor
                    if (editor.getCurrentPage().name === 'Page 1') {
                        editor.renamePage(editor.getCurrentPage(), "Strona 1")
                    }
                    editor.updateInstanceState({isGridMode: false})
                }}
            />
        </div>
        <BoardShareModal isOpen={shareBoardModalOpen} setOpen={setShareBoardModalOpen}
                         board={{
                             id: "example",
                             previewUrl: '',
                             description: "Na normalnej tablicy będziesz mieć możliwość edytowania tytułu i opisu.",
                             studentId: "example",
                             createdAt: new Date().toISOString(),
                             lastOpenedAt: new Date().toISOString(),
                             title: "Przykładowa tablica"
                         }} readonly={true}/>
        <BoardInfoModal isOpen={boardInfoModalOpen} setOpen={setBoardInfoModalOpen}
                        board={{
                            id: "example",
                            previewUrl: '',
                            description: "Na normalnej tablicy będziesz mieć możliwość edytowania tytułu i opisu.",
                            studentId: "example",
                            createdAt: new Date().toISOString(),
                            lastOpenedAt: new Date().toISOString(),
                            title: "Przykładowa tablica"
                        }}
                        isTutor={false}
                        onEditInfoClick={() => {
                        }}/>
    </>
}