import React, {useState} from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {TransactionService} from "../../../service/TransactionService";
import AnimatedLink from "../../ui/motion/AnimatedLink";

interface SubscriptionWarningProps {
    expirationDate: Date;
    infoOnly?: boolean;
}

export default function SubscriptionWarning({
                                                expirationDate,
                                                infoOnly
                                            }: SubscriptionWarningProps) {
    const [isCreatingTransaction, setIsCreatingTransaction] = useState(false);

    const subscriptionExpired = expirationDate.getTime() < new Date().getTime();

    const formattedDate = expirationDate.toLocaleDateString("pl-PL", {
        weekday: "long", // Display the day of the week
        year: "numeric",
        month: "long",
        day: "numeric",
    });

    async function createTransactionAndRedirect() {
        setIsCreatingTransaction(true);
        const {url} = await TransactionService.createTransaction();
        window.location.href = url;
    }

    return (
        <>
            <h3 className={`${infoOnly ? "text-warning" : "text-danger"} text-center mb-4 fs-8`}>
                {
                    !subscriptionExpired && <>
                        {infoOnly ? '' : 'Uwaga!'} Twoja subskrypcja wygasa {formattedDate.includes("wtorek") ? 'we' : 'w'} {formattedDate.replace("niedziela", "niedzielę").replace("sobota", "sobotę").replace("środa", "środę")}.
                    </>
                }

                {
                    subscriptionExpired && "Uwaga! Twoja subskrypcja wygasła."
                }
            </h3>
            <p className="text-white text-center mb-4 fs-11 fw-medium">
                {
                    subscriptionExpired && "Odnów ją, aby dalej korzystać z serwisu."
                }
                {
                    !subscriptionExpired && <>
                        {infoOnly ?
                            "Jeżeli chcesz dalej korzystać ze wszystkich funkcji serwisu, odnów subskrypcję przed upływem powyższego terminu." :
                            "Odnów ją w najbliższym czasie, aby nie stracić dostępu do wszystkich funkcji."
                        }
                    </>
                }

            </p>
            {
                infoOnly && <p className='text-white text-center mb-4 fs-11 fw-medium'> Każdorazowe odnowienie subskrypcji kosztuje 29.99PLN i przedłuża ją o 31 dni.</p>
            }
            <div className={`row justify-content-center ${infoOnly ? 'mb-4' : 'mb-8'}`}>
                <div className="col col-md-auto">
                    {
                        infoOnly ? <AnimatedButton
                            className="btn btn-warning w-100 px-7 py-4 fs-10 fw-medium text-success-light rounded shadow"
                            type="button"
                            disabled={isCreatingTransaction}
                            onClick={createTransactionAndRedirect}
                        >
                            Odnów teraz
                        </AnimatedButton> : <AnimatedLink
                            className="btn btn-warning w-100 px-7 py-4 fs-10 fw-medium text-success-light rounded shadow"
                            to='/subskrypcja'
                        >
                            Zarządzaj subskrypcją
                        </AnimatedLink>
                    }

                </div>
            </div>
            {
                infoOnly && <p className='text-white text-center mb-4 fs-12 fw-medium'>Potrzebujesz faktury? Napisz do nas na maila <i>kontakt@tablicanakorki.pl</i> po dokonaniu płatności.</p>
            }
        </>
    );
};