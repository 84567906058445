import {format, parseISO} from "date-fns";

export function getCurrentDate() {
    const date = new Date();
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}

export function formatISODateString(dateString: string) {
    try {
        const date = parseISO(dateString); // Parse the ISO date string
        return format(date, 'dd.MM.yyyy'); // Format to your desired format
    } catch (error) {
        return "";
    }
}