import {useSubmit} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import {Student} from "../../../model/tutor/Student";
import FormModal from "../../ui/modal/FormModal";
import FormInput from "../../ui/form/FormInput";
import FormTextArea from "../../ui/form/FormTextArea";

interface AddOrEditStudentModalProps {
    isOpen: boolean
    setOpen: (value: boolean) => void
    student?: Student
    setStudentToEdit?: React.Dispatch<React.SetStateAction<Student | null>>
}

export default function AddOrEditStudentModal(props: AddOrEditStudentModalProps) {
    const {isOpen, setOpen, student, setStudentToEdit} = props

    const submit = useSubmit();
    const {isSubmitting} = useSubmitFormAction('/', null, () => {
        setOpen(false);
        setName("");
        setLastName("");
        setDescription("");
    });

    const [name, setName] = useState(student?.name ?? "");
    const [lastName, setLastName] = useState(student?.lastName ?? "");
    const [description, setDescription] = useState(student?.description ?? "");

    useEffect(() => {
        setName(student?.name ?? "");
        setLastName(student?.lastName ?? "")
        setDescription(student?.description ?? "");
    }, [student])

    const isEditing = !!student;

    function submitForm() {
        submit({
            id: student?.id ?? "",
            name: name,
            lastName: lastName,
            description: description,
            requestType: isEditing ? "EDIT" : "CREATE"
        }, {
            method: "POST",
        });
    }

    return <FormModal isOpen={isOpen} setOpen={setOpen}
                      title={student ? `Edytujesz ucznia ${student.name}` : 'Dodajesz nowego ucznia'}
                      form={
                          <>
                              <div className='col-12 col-md-6'>
                                  <FormInput label='Imię ucznia' placeholder='Podaj imię' value={name}
                                             onChange={setName}/>
                              </div>
                              <div className='col-12 col-md-6'>
                                  <FormInput label='Naziwsko ucznia' placeholder='Podaj nazwisko' value={lastName}
                                             onChange={setLastName}/>
                              </div>
                              <div className='col-12'>
                                  <FormTextArea label='Opis ucznia (klasa, poziom, etc.)' placeholder='Podaj opis'
                                                value={description} onChange={setDescription} rows={3}/>
                              </div>
                          </>
                      }
                      isSubmitting={isSubmitting}
                      onSubmit={submitForm}
                      onClose={() => {
                          if (setStudentToEdit) {
                              setStudentToEdit(null);
                          }
                      }}
    />
}