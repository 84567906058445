import React from "react";
import MainDesign from "../../../components/ui/MainDesign";

export default function RegulationsPage() {
    return <MainDesign containerStyles="container py-32">
        <div className='mb-4 text-center mw-md-2xl mx-auto'>
            <span className='mb-4 badge bg-success text-white text-uppercase shadow'>
                Regulamin
            </span>
            <h1
                className='font-heading mb-4 fs-5 text-white'
                style={{letterSpacing: '-1px'}}
            >
                Regulamin serwisu TABLICANAKORKI.PL
            </h1>
        </div>
        <div className='mw-md-2xl mx-auto py-3'>
            <p className='fs-9 text-white mb-4'>
                Treść regulaminu znajduje się pod <a className='link-success text-decoration-underline'
                                                     href='https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/Regulamin+TablicaNaKorki.PL+24.10.pdf'>tym
                linkiem</a>.
            </p>
        </div>

        <div className='mt-8 text-center mw-md-2xl mx-auto'>
                <span className='text-success fw-medium'>
                    Ostatnia aktualizacja: 24.10.2024
                </span>
        </div>
    </MainDesign>
}