import {useEffect} from 'react';
import {FetcherWithComponents, useActionData, useNavigate, useNavigation} from 'react-router-dom';
import {useToast} from "../components/ui/toast/ToastContext";
import {ActionResponse} from "../model/Common";

export function useSubmitFormAction(successRedirectPath = '/', fetcher: FetcherWithComponents<any> | null = null, callIfSuccess?: (body: string) => void, redirect: boolean = true, customMessage?: string) {
    const {showMessage} = useToast();
    const navigate = useNavigate();
    const navigation = useNavigation();
    let actionData = useActionData();
    let isSubmitting = navigation.state === 'submitting';

    if (fetcher) {
        actionData = fetcher.data;
        isSubmitting = fetcher.state === 'submitting';
    }

    useEffect(() => {
        if (actionData) {
            const {body, status} = actionData as ActionResponse;
            if (body) {
                if (customMessage) {
                    showMessage(customMessage)
                } else {
                    showMessage(body);
                }
            }
            if (status === 200) {
                if (redirect) {
                    navigate(successRedirectPath);
                }
                if (callIfSuccess) {
                    callIfSuccess(body);
                }
            }
        }
    }, [actionData, navigate, showMessage, successRedirectPath]);

    return {isSubmitting};
}
