import React, {useState} from "react";
import {useParams, useSubmit} from "react-router-dom";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import CustomSelect from "../../ui/form/CustomSelect";
import LeftArrow from "../../ui/element/LeftArrow";
import BoardPreview from "../board/BoardPreview";
import AddOrEditBoardModal from "../board/AddOrEditBoardModal";
import BoardShareModal from "../board/BoardShareModal";
import ConfirmModal from "../../ui/modal/ConfirmModal";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import {Board} from "../../../model/tutor/Board";
import {Student} from "../../../model/tutor/Student";

interface StudentBoardsProps {
    boards: Board[];
    student: Student;
    readonly: boolean;
}

type PathParams = {
    studentId: string | undefined;
};

export default function StudentBoards(props: StudentBoardsProps) {
    const {boards, student, readonly} = props;
    const {studentId} = useParams<PathParams>();

    const {isSubmitting} = useSubmitFormAction(`/uczen/${studentId}`, null, () => {
        setAddOrEditModalOpen(false);
        setDeleteModalOpen(false);
    });

    const submit = useSubmit();

    const [boardToEdit, setBoardToEdit] = useState<Board | null>(null);
    const [addOrEditModalOpen, setAddOrEditModalOpen] = useState(false);

    const [boardToShare, setBoardToShare] = useState<Board | null>(null);
    const [shareModalOpen, setShareModalOpen] = useState(false);

    const [boardToDelete, setBoardToDelete] = useState<Board | null>(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState(""); // State for search term
    const [sortOption, setSortOption] = useState("newest-activity"); // State for sort option

    function submitDeleteForm() {
        submit(
            {
                id: boardToDelete!!.id,
            },
            {
                method: "DELETE",
            }
        );
    }

    // Filter boards based on search term
    const filteredBoards = boards.filter((board) => {
        const term = searchTerm.toLowerCase();
        return (
            board.title.toLowerCase().includes(term) ||
            (board.description && board.description.toLowerCase().includes(term))
        );
    });

    // Sort the filtered boards based on sortOption
    const sortedBoards = [...filteredBoards].sort((a, b) => {
        switch (sortOption) {
            case "newest-activity":
                return (
                    new Date(b.lastOpenedAt).getTime() - new Date(a.lastOpenedAt).getTime()
                );
            case "oldest-activity":
                return (
                    new Date(a.lastOpenedAt).getTime() - new Date(b.lastOpenedAt).getTime()
                );
            default:
                return 0;
        }
    });

    return (
        <>
            <h1 className="text-center mb-10 text-white">
                {
                    readonly ? `Wszystkie tablice ucznia ${student.name} ${student.lastName}` : `Twoje tablice z ${student.name} ${student.lastName}`
                }

            </h1>
            <div className="mw-5xl mx-auto">
                {/* Top-left link */}
                {
                    !readonly && <div className="d-flex justify-content-start mb-3">
                        <AnimatedLink
                            className="link-warning text-danger text-lg-left fs-11 py-3 mx-4 h-100 d-inline-block"
                            to="/"
                        >
                            <LeftArrow/>
                            <span className="ms-2 fw-semibold fs-10">
                          Powrót do wszystkich uczniów
                        </span>
                        </AnimatedLink>
                    </div>
                }


                {/* Row with button, search, and select */}
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-4">
                        <AnimatedButton
                            className="btn btn-lg btn-success text-success-light shadow w-100"
                            style={{whiteSpace: "nowrap"}}
                            disabled={readonly}
                            onClick={() => setAddOrEditModalOpen(true)}
                        >
                        Utwórz nową tablicę
                        </AnimatedButton>
                    </div>

                    <div className="col-lg-5 col-md-4 mt-2 mt-md-0">
                        <input
                            type="text"
                            className="form-control form-control-lg w-100 p-4 bg-dark text-white"
                            placeholder="Wyszukaj..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>

                    <div className="col-lg-4 col-md-4 mt-2 mt-md-0">
                        <div className="d-inline-block w-100">
                            <CustomSelect
                                value={sortOption}
                                onChange={(e) => setSortOption(e.target.value)}
                                customStyles="form-control form-control-lg w-100 bg-dark text-white p-4"
                            >
                                <option value="newest-activity">
                                    Sortuj: Najnowsza aktywność
                                </option>
                                <option value="oldest-activity">
                                    Sortuj: Najstarsza aktywność
                                </option>
                            </CustomSelect>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="text-white"/>
            <div className="mw-md-5xl mx-auto">
                <div className="mb-20 row g-8">
                    {
                        boards.length === 0 && (
                            !readonly ? <div className='mx-3 mt-8'>
                                <h2>Nie masz jeszcze żadnych tablic z uczniem {student.name} {student.lastName}</h2>
                                <p className='text-white fw-medium'>Dodaj pierwszą tablicę, klikając w powyższy
                                    przycisk.</p>
                            </div> : <h2 className='px-10 pt-5 text-warning'>Korepetytor nie dodał jeszcze żadnej tablicy.</h2>
                        )
                    }
                    {sortedBoards.map((board) => (
                        <BoardPreview
                            key={board.id}
                            board={board}
                            readonly={readonly}
                            setBoardToEdit={setBoardToEdit}
                            setEditModalOpen={setAddOrEditModalOpen}
                            setDeleteModalOpen={setDeleteModalOpen}
                            setBoardToDelete={setBoardToDelete}
                            setShareModalOpen={setShareModalOpen}
                            setBoardToShare={setBoardToShare}
                        />
                    ))}
                </div>
            </div>
            <AddOrEditBoardModal
                isOpen={addOrEditModalOpen}
                setOpen={setAddOrEditModalOpen}
                board={boardToEdit!}
                setBoardToEdit={setBoardToEdit}
                studentId={studentId!!}
            />
            <BoardShareModal
                isOpen={shareModalOpen}
                setOpen={setShareModalOpen}
                board={boardToShare!!}
                setBoardToShare={setBoardToShare}
            />
            <ConfirmModal
                isOpen={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                title={`Czy na pewno chcesz usunąć tablicę '${boardToDelete?.title}'?`}
                subtitle="Tej czynności nie będzie dało się cofnąć."
                isSubmitting={isSubmitting}
                confirmButtonAction={submitDeleteForm}
            />
        </>
    );
}