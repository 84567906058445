import {HttpMethod, HttpService} from "./HttpService";
import {User} from "../model/tutor/User";

export class UserService {
    public static async getUser(): Promise<User> {
        return HttpService.sendRequest<User>(
            HttpMethod.GET,
            `/user`,
        );
    }
}