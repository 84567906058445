import React from "react";
import Footer from "../common/footer/Footer";

export default function TutorFooter() {
    return <Footer isGuest={false} links={
        [
            {
                name: 'Zarządzaj subskrypcją',
                to: '/subskrypcja'
            },
            {
                name: 'Polityka prywatności',
                to: '/polityka-prywatnosci'
            },
            {
                name: 'Regulamin',
                to: '/regulamin'
            }
        ]
    }/>
}