import React from 'react';
import MainDesign from "../../ui/MainDesign";
import AnimatedLink from "../../ui/motion/AnimatedLink";

export default function IndexCta() {
    return (
        <React.Fragment>
            <>
                <MainDesign containerStyles='py-28 container' minHeight='0vh'>
                        <div className="mw-4xl mx-auto text-center" style={{zIndex: 10}}>
                            <h2 className="mb-4 fs-5 font-heading" style={{letterSpacing: '-1px'}}>Na co czekasz?</h2>
                            <p className="mb-6 fs-9 fw-medium text-secondary-light">“The best way to get something done is to start.”
                            </p>
                            <div className="row justify-content-center">
                                <div className="col col-md-auto">
                                    <AnimatedLink
                                    className="btn btn-success w-100 px-7 py-4 fs-10 fw-medium text-success-light rounded shadow"
                                    to="/rejestracja">Zaczynam korzystać</AnimatedLink></div>
                            </div>
                        </div>
                </MainDesign>
            </>
        </React.Fragment>
    );
}

