import {HttpMethod, HttpService} from "./HttpService";
import {User} from "../model/tutor/User";
import {Transaction} from "../model/tutor/Transaction";

export class TransactionService {

    public static async createTransaction(): Promise<{ url: string }> {
        return HttpService.sendRequest<{ url: string }>(
            HttpMethod.POST,
            `/transaction`,
        );
    }

    public static async getAllUserTransactions(): Promise<Transaction[]> {
        return HttpService.sendRequest<Transaction[]>(
            HttpMethod.GET,
            `/transaction`,
        );
    }
}