import {NavLink} from "react-router-dom";
import React from "react";
import {motion} from "framer-motion";
import {NavHashLink} from "react-router-hash-link";

interface NavLinkItemProps {
    mainPage?: boolean,
    liStyles?: string,
    burgerMenu?: boolean
    title: string,
    to: string,
    onClick?: () => void;
    hashLink?: boolean;
}

const linkInactive = 'nav-link p-0 link-secondary-light';
const linkInactiveBurger = 'nav-link link-secondary-light';

export default function NavLinkItem(props: NavLinkItemProps) {
    const {mainPage, liStyles, burgerMenu, title, to, onClick, hashLink} = props;
    return <motion.li className={`nav-item ${liStyles ? liStyles : ''}`} whileHover={{scale: 1.1}}>
        {
            hashLink ? <NavHashLink className={(burgerMenu ? linkInactiveBurger : linkInactive)}
                                     onClick={onClick}
                                     to={to}
                                     end={mainPage}>
                {title}
            </NavHashLink> :  <NavLink
                className={(burgerMenu ? linkInactiveBurger : linkInactive)}
                onClick={onClick}
                to={to}
                end={mainPage}>
                {title}
            </NavLink>
        }

    </motion.li>
}