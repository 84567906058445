import React from 'react';
import MainDesign from "../../ui/MainDesign";
import FaqItem from "../../ui/element/item/FaqItem";

export default function IndexFAQ() {
    return (
        <React.Fragment>
            <>
                <MainDesign containerStyles='py-20 container'>
                        <div className="mb-32 text-center mw-md-4xl mx-auto">
                            <span className="badge mb-4 bg-success-dark text-success text-uppercase shadow">Faq</span>
                            <h2 className="font-heading mb-4 fs-5 text-white" style={{letterSpacing: '-1px'}}>Najczęściej zadawane
                                pytania</h2>
                            <p className="mb-0 fs-9 fw-medium text-secondary-light">Masz jakieś pytania? Znajdź odpowiedź niżej
                                lub skontaktuj się z nami.</p>
                        </div>
                        <div className="mw-md-6xl mx-auto">
                            <div className="row g-8">
                                <FaqItem question='Czy mogę przetestować działanie aplikacji za darmo?' answer='Oczywiście. Po stworzeniu konta masz 7 dni na przetestowanie wszystkich funkcjonalności. Przez ten czas, nie musisz podawać żadnych danych do płatności. '/>
                                <FaqItem question='Co wyróżnia Tablicę Na Korki od innych tablic?' answer='Tablica Na Korki jest specjalnie przystosowana do prowadzenia zajęć online i potrzeb nauczycieli. Jest prosta i intuicyjna w obsłudze. Umożliwia zarządzanie uczniami i tablicami w najefektywniejszy sposób. '/>
                                <FaqItem question='Czy uczeń musi tworzyć konto, aby korzystać z aplikacji?' answer='Nie. Wystarczy udostępnić mu link do tablicy i uczeń od razu może zacząć z niej korzystać. Co więcej, jednym kliknięciem możesz udostępnić uczniowi folder zawierający wszystkie jego tablice.'/>
                                <FaqItem question='Czy są jakieś limity?' answer='Nie. Możesz dodawąć dowolną ilość uczniów i tablic, a także przesyłać tyle plików, ile chcesz. Tablice są dostępne przez 180 dni.'/>
                                <FaqItem question='Ile kosztuje subskrypcja i co jest w niej zawarte?' answer='Subskrypcja kosztuje 29.99zł miesięcznie. Nie ma limitu długości subskrypcji. Po każdym miesiącu możesz podjąć decyzję, czy chcesz dalej korzystać z Tablicy Na Korki, czy nie.'/>
                                <FaqItem question='Co się stanie, jak nie przedłużę subskrypcji?' answer='Stracisz dostęp do dodawania uczniów i tablic. Tablice nie zostaną usunięte, jednak nie będzie można z nich korzystać. Aby kontynuować korzystanie z aplikacji, wystarczy odnowić subskrypcję.'/>
                            </div>
                        </div>
                </MainDesign>
            </>
        </React.Fragment>
    );
}

