import React from 'react';
import MainDesign from "../../ui/MainDesign";

export default function IndexContact() {
    return <>
        <MainDesign containerStyles='py-16 container' minHeight='0vh' id='kontakt'>
            <div className="row g-16 mw-md-6xl mx-auto">
                <div className="col-12">
                    <div className="text-center">
                        <svg className="mb-6 text-success" width={72} height={72} viewBox="0 0 72 72"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx={36} cy={36} r={36} fill="currentColor"/>
                            <path
                                d="M46.9467 31.76L38.6667 23.7067C37.9653 23.0398 37.0345 22.6679 36.0667 22.6679C35.0989 22.6679 34.1681 23.0398 33.4667 23.7067L25.1867 31.7067C24.8188 32.0278 24.5229 32.4231 24.3184 32.8665C24.1139 33.31 24.0054 33.7917 24 34.28V45.72C24.0141 46.6902 24.4121 47.6152 25.1068 48.2925C25.8016 48.9697 26.7365 49.344 27.7067 49.3333H44.2933C45.2635 49.344 46.1984 48.9697 46.8932 48.2925C47.5879 47.6152 47.9859 46.6902 48 45.72V34.28C47.999 33.8113 47.9054 33.3474 47.7246 32.915C47.5439 32.4825 47.2795 32.09 46.9467 31.76ZM35.2533 25.6267C35.4573 25.4402 35.7237 25.3368 36 25.3368C36.2763 25.3368 36.5427 25.4402 36.7467 25.6267L44 32.6667L36.7067 39.7067C36.5027 39.8931 36.2363 39.9965 35.96 39.9965C35.6837 39.9965 35.4173 39.8931 35.2133 39.7067L28 32.6667L35.2533 25.6267ZM45.3333 45.72C45.3162 45.9817 45.1982 46.2266 45.0043 46.4031C44.8103 46.5797 44.5555 46.6741 44.2933 46.6667H27.7067C27.4445 46.6741 27.1897 46.5797 26.9957 46.4031C26.8018 46.2266 26.6838 45.9817 26.6667 45.72V35.1333L32.0667 40.3333L29.8533 42.4667C29.605 42.7165 29.4656 43.0544 29.4656 43.4067C29.4656 43.7589 29.605 44.0969 29.8533 44.3467C29.9773 44.4767 30.1262 44.5803 30.2911 44.6513C30.4561 44.7224 30.6337 44.7593 30.8133 44.76C31.1566 44.7586 31.4862 44.6249 31.7333 44.3867L34.0933 42.12C34.6795 42.4782 35.3531 42.6677 36.04 42.6677C36.7269 42.6677 37.4005 42.4782 37.9867 42.12L40.3467 44.3867C40.5938 44.6249 40.9234 44.7586 41.2667 44.76C41.4463 44.7593 41.6239 44.7224 41.7889 44.6513C41.9538 44.5803 42.1027 44.4767 42.2267 44.3467C42.475 44.0969 42.6144 43.7589 42.6144 43.4067C42.6144 43.0544 42.475 42.7165 42.2267 42.4667L40 40.3333L45.3333 35.1333V45.72Z"
                                fill="white"/>
                        </svg>
                        <h2 className="mb-2 font-heading fs-7">Masz pytania?</h2>
                        <p className="mb-0 fs-9 text-success fw-medium">kontakt@tablicanakorki.pl</p>
                    </div>
                </div>
                {/*<div className="col-12 col-md-6">*/}
                {/*    <div className="text-center">*/}
                {/*        <svg className="mb-6 text-success" width={72} height={72} viewBox="0 0 72 72"*/}
                {/*             fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*            <circle cx={36} cy={36} r={36} fill="currentColor"/>*/}
                {/*            <path*/}
                {/*                d="M44 25.9733C41.8783 23.8516 39.0006 22.6596 36 22.6596C32.9994 22.6596 30.1217 23.8516 28 25.9733C25.8783 28.0951 24.6863 30.9727 24.6863 33.9733C24.6863 36.9739 25.8783 39.8516 28 41.9733L35.0267 49.0133C35.1506 49.1383 35.2981 49.2375 35.4606 49.3052C35.623 49.3729 35.7973 49.4077 35.9733 49.4077C36.1493 49.4077 36.3236 49.3729 36.4861 49.3052C36.6486 49.2375 36.796 49.1383 36.92 49.0133L44 41.9067C46.1129 39.7938 47.2998 36.9281 47.2998 33.94C47.2998 30.9519 46.1129 28.0862 44 25.9733ZM42.0933 40L36 46.12L29.9067 40C28.7028 38.7951 27.8833 37.2603 27.5516 35.5897C27.2199 33.9191 27.3909 32.1877 28.0431 30.6142C28.6952 29.0408 29.7992 27.6961 31.2155 26.75C32.6318 25.8039 34.2968 25.2989 36 25.2989C37.7032 25.2989 39.3682 25.8039 40.7845 26.75C42.2008 27.6961 43.3048 29.0408 43.9569 30.6142C44.6091 32.1877 44.7801 33.9191 44.4484 35.5897C44.1167 37.2603 43.2971 38.7951 42.0933 40ZM32 29.88C30.9236 30.9597 30.3192 32.4221 30.3192 33.9467C30.3192 35.4712 30.9236 36.9336 32 38.0133C32.7997 38.8144 33.8181 39.3614 34.9275 39.5859C36.0369 39.8103 37.1879 39.7021 38.2361 39.2749C39.2842 38.8476 40.1829 38.1204 40.8193 37.1843C41.4557 36.2483 41.8015 35.1452 41.8133 34.0133C41.8193 33.2576 41.6737 32.5083 41.3852 31.8098C41.0966 31.1114 40.671 30.4778 40.1333 29.9467C39.6049 29.4061 38.9748 28.9754 38.2792 28.6793C37.5837 28.3832 36.8364 28.2276 36.0805 28.2214C35.3246 28.2152 34.5749 28.3585 33.8746 28.6432C33.1743 28.9278 32.5372 29.3482 32 29.88ZM38.2533 36.12C37.748 36.633 37.0803 36.9545 36.3642 37.0297C35.648 37.1048 34.9281 36.9288 34.3273 36.5319C33.7266 36.135 33.2824 35.5417 33.0707 34.8534C32.8589 34.1652 32.8929 33.4248 33.1666 32.7589C33.4403 32.0929 33.9369 31.5427 34.5714 31.2023C35.2059 30.8619 35.939 30.7525 36.6452 30.8928C37.3515 31.0331 37.9871 31.4143 38.4433 31.9714C38.8996 32.5284 39.1482 33.2266 39.1467 33.9467C39.1273 34.7697 38.782 35.5514 38.1867 36.12H38.2533Z"*/}
                {/*                fill="white"/>*/}
                {/*        </svg>*/}
                {/*        <h2 className="mb-2 font-heading fs-7">Media społecznościowe</h2>*/}
                {/*        <p className="mb-4 fs-9 text-secondary-light fw-medium">Lorem ipsum dolor sit amet</p>*/}
                {/*        <p className="mb-0 fs-9 text-success fw-medium">1686, Geraldine Lane</p>*/}
                {/*        <p className="mb-0 fs-9 text-success fw-medium">New York, NY 10013</p>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </MainDesign>
    </>
}

