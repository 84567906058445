import React, {useState} from 'react';
import {signIn} from "aws-amplify/auth";
import {Link} from "react-router-dom";
import MainDesign from "../../ui/MainDesign";
import SmallSquareLogo from "../../ui/logo/SmallSquareLogo";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {AuthService} from "../../../service/AuthService";
import {validateEmail} from "../../../util/Util";

function getTranslation(errorMessage: string) {
    switch (errorMessage) {
        case "User does not exist.": {
            return "Nieprawidłowa nazwa użytkownika lub hasło."
        }
        case "Incorrect username or password.": {
            return "Nieprawidłowa nazwa użytkownika lub hasło."
        }
        case "There is already a signed in user.": {
            return "Użytkownik jest już zalogowany. Przejdź do strony głównej aby korzystać z aktualnego konta, lub wyloguj się."
        }
    }
    return errorMessage;
}

export default function LoginComponent() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    const isInputValid = validateEmail(email) && password.length >= 5;

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setIsSubmitting(true);
        try {
            const output = await signIn({
                username: email,
                password: password
            });

            if (!output.isSignedIn) {
                if (output.nextStep.signInStep === 'CONFIRM_SIGN_UP') {
                    setErrorMessage('Twoje konto nie zostało potwierdzone. Zarejestruj się ponownie.')
                }
            }

            window.location.href = '/'
        } catch (error: any) {
            console.error('Error signing in', error);
            setErrorMessage(error.message || 'Error signing in');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <MainDesign containerStyles='container py-28'>
            <div className="mb-7 text-center mw-md mx-auto">

                <SmallSquareLogo width='58px' disableLink={true}/>
                <h2 className="font-heading mb-4 fs-7 mt-8">Zaloguj się do swojego konta</h2>
                <p className="mb-0 fs-9 fw-medium text-secondary-light">
                    Uzyskaj dostęp do wszystkich funkcjonalności
                </p>
            </div>
            <form className="mw-sm mx-auto" onSubmit={handleSubmit}>
                <div className="mb-4 row g-6">
                    <div className="col-12">
                        <div className="form-group">
                            <label className="mb-1 fw-medium text-white" htmlFor="signInInput1-1">
                                Email
                            </label>
                            <input
                                className="form-control text-dark shadow"
                                id="signInInput1-1"
                                type="email"
                                placeholder="Podaj swój adres email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label className="mb-1 fw-medium text-white" htmlFor="signInInput1-2">
                                Hasło
                            </label>
                            <input
                                className="form-control text-dark shadow"
                                id="signInInput1-2"
                                type="password"
                                placeholder="Podaj swoje hasło"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                {errorMessage && (
                    <div className="mb-3">
                        <p className="text-danger">{getTranslation(errorMessage)}</p>
                    </div>
                )}

                <p className="mb-1 fs-13 fw-medium text-left mt-2 text-white">
                    <span>Logując się, akceptujesz </span>
                    <Link className="text-success link-success" to="/polityka-prywatnosci">
                        politykę prywatności
                    </Link>
                    <span> oraz </span>
                    <Link className="text-success link-success" to="/regulamin">
                        regulamin
                    </Link>
                    <span>.</span>
                </p>

                <div className="mb-4 row">
                    <div className="col-12">
                        <AnimatedButton
                            className="btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow"
                            type="submit"
                            disabled={isSubmitting || !isInputValid}
                        >
                            Zaloguj się
                        </AnimatedButton>
                    </div>
                </div>
                <div className='mb-6 row g-4'>
                    <div className='col-12'>
                        <AnimatedButton
                            className='btn btn-lg btn-outline-light d-flex flex-wrap align-items-center justify-content-center fs-11 py-3 w-100 text-white shadow'
                            onClick={() => AuthService.facebookLogin()}
                            bounceEffect
                            customScale={1.05}
                            disabled={isSubmitting}
                        >

                            <img
                                className='me-2'
                                style={{width: "24px"}}
                                src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/social/social-fb.png'
                                alt='logo facebook'
                            />
                            <span>Kontynuuj z Facebookiem</span>
                        </AnimatedButton>
                    </div>
                </div>
                <p className="mb-4 fs-13 fw-medium text-white text-center">
                    <span>Nie masz jeszcze konta? </span>
                    <Link className="text-success link-success" to="/rejestracja">
                        Zarejestruj się.
                    </Link>
                </p>
                <p className="mb-0 fs-13 fw-medium text-light-dark text-center">
                        <span className="col-auto">
                            <AnimatedLink className='fs-13 fw-medium text-success link-success' to='/zmien-haslo'>
                                Nie pamiętam hasła
                            </AnimatedLink>
                        </span>
                </p>

            </form>

        </MainDesign>
    );
}