import React from 'react';
import MainDesign from "../../ui/MainDesign";
import AdvantageItem from "../../ui/element/item/AdvantageItem";
import AnimatedLink from "../../ui/motion/AnimatedLink";

export default function IndexPricing() {
    return <>
        <MainDesign containerStyles='container py-20' id='cennik'>
            <div className="mb-12 text-center">
                            <span
                                className="badge mb-4 bg-success-dark text-success text-uppercase shadow">CENNIK</span>
                <h2 className="font-heading mb-6 fs-5 text-white" style={{letterSpacing: '-1px'}}>Jedna opcja, jedna
                    cena.</h2>
            </div>
            <div>
                <div className="h-100 p-8 bg-light-dark rounded shadow mx-auto mw-md-2xl text-center">
                    <div className="mb-7 d-flex flex-column align-items-center">
                        <h3 className="mb-2 fs-6 fw-semibold" style={{letterSpacing: '-1px'}}>Wszystko,
                            czego potrzebujesz.</h3>
                        <p className="mb-6 fw-medium text-secondary-light">Dla każdego korepetytora,
                            który ceni sobie profesjonalizm.</p>
                        <h3 className="mb-2 fs-8 fw-semibold text-white" style={{letterSpacing: '-1px'}}>7 dni za darmo
                            , a następnie:</h3>
                        <div className="d-flex">
                            <div className="fs-7 fw-bold mt-1 pe-1 text-white">PLN</div>
                            <div className="fs-3 fw-semibold">
                                <span className='text-white'>29.99</span>
                                <span className="fs-11 text-secondary-light">/miesiąc</span>
                            </div>
                        </div>
                    </div>
                    <AnimatedLink
                        className="mb-8 btn btn-lg btn-success fs-10 py-4 w-100 text-success-light shadow shadow shadow"
                        to="/rejestracja">Przetestuj już teraz</AnimatedLink>
                    <div className='text-start'>
                        <AdvantageItem title='Nielimitowana ilość tablic.'/>
                        <AdvantageItem title='Nielimitowana ilość uczniów.'/>
                        <AdvantageItem title='Nielimitowany przesył plików.'/>
                        <AdvantageItem title='Synchronizacja w czasie rzeczywistym.'/>
                        <AdvantageItem title='Tablice przechowywane są przez 180 dni.'/>
                        <AdvantageItem title='Udostępniaj zarówno foldery, jak i pojedyncze tablice.'/>
                    </div>
                </div>
            </div>
        </MainDesign>
    </>

}

